.m-inputGroup {
    position: relative;
    margin-bottom: 15px;

    &.-password {
        input {
            padding-left: 59px;

            &[type='password'] {
                letter-spacing: 4px;

                &::placeholder {
                    letter-spacing: initial;
                }
            }
        }
    }

    &.-uppercase {
        input {
            text-transform: uppercase;
        }
    }

    &.-icon {
        input {
            padding-right: 34px;
        }
    }

    &.-error {
        input {
            border-color: $red;
        }
    }

    &.-hasValue {
        input {
            background-color: $white;
        }
    }

    &.-disabled {
        label {
            color: rgba($black, 0.7);
        }

        input {
            color: $black;
            opacity: 0.7;
        }
    }

    input {
        width: 100%;
        height: 44px;
        padding: 0 15px 0 15px;
        border-radius: 6px;
        border: 1px solid $gray-5;
        background-color: $white;
        outline: 0;
        color: $black;
        font-size: 13px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $secondary-color;

            ~ svg {
                stroke: $secondary-color;
            }
        }

        &::placeholder {
            opacity: 1;
            color: $gray-6;
        }
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    label {
        font-size: 13px;
        line-height: 16px;
        .a-require-star {
            color: $red;
        }
    }

    svg {
        position: absolute;
        right: 10px;
        top: 35px;
        transition: stroke 0.3s;
    }

    &__error {
        display: block;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity 0.3s;
        letter-spacing: -0.2px;
        line-height: 16px;
        margin-top: 3px;

        &.-active {
            opacity: 1;
        }
    }
}
