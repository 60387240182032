$max-wrapper-width: 67vw;// Note: This prevent from map shrink pib/airport cuz it have less width

.m-modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;

    &.-show {
        opacity: 1;
        pointer-events: initial;

        .m-modal__content {
            transform: translateY(0);
        }
    }

    &.-small {
        .wrapper {
            width: min-content;
        }
    }

    &.-custom {
        .wrapper {
            width: fit-content;
            max-width: $max-wrapper-width;
        }

        .m-modal__content {
            max-height: 80vh;
        }

        table {
            th {
                white-space: nowrap;
            }
        }
    }

    &.-map {
        .wrapper {
            width: fit-content;
            max-width: $max-wrapper-width;
        }
    }

    &.-fixed {
        .m-modal__body {
            width: 100%;
        }
    }

    .wrapper {
        width: calc(100% - 40px);
        max-width: 500px;
        max-height: 80vh;
        margin: auto;
    }

    &__content {
        background-color: $white;
        padding: 25px 30px;
        overflow: auto;
        box-shadow: 0 4px 8px 0 rgba($black, 0.2),
            0 6px 20px 0 rgba($black, 0.19);
        transition: transform 0.3s;
        border-radius: 6px;
        transform: translateY(-100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        font-size: 16px;

        @include scrollbar;

        &.-clickworker {
            overflow: inherit;
        }
    }

    &__buttons {
        margin-top: 20px;
        display: flex;

        &.-end {
            justify-content: flex-end;
        }

        .a-btn {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 0.3s;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
        }

        &::before {
            transform: translateX(-50%) rotate(45deg);
        }

        &::after {
            transform: translateX(-50%) rotate(-45deg);
        }

        &:hover {
            transform: rotate(90deg);
        }
    }
}
