.m-sidebar {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: $sidebar-height;
    // height: 100vh;
    width: $sidebar-width;
    // padding: 20px 10px;
    border: 1px solid $gray-10;
    border-top: 0;
    border-left: 0;
    background-color: $white;
    transition: 0.5s;

    &.-lowerLevel {
        margin-top: $header-height;
    }

    &.-closed {
        width: $sidebar-closed-width;

        @include media-down(s) {
            width: $sidebar-closed-mobile-width;
        }

        .m-sidebar {
            &__itemLabel {
                opacity: 0;
                width: 0;
                color: $white;
            }

            &__toggler {
                background-color: $primary-color;
                border-color: $primary-color;

                &::after {
                    left: calc(50% - 2px);
                    border-color: $white;
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }

            &__itemContainer {
                &.-active {
                    .m-sidebar__subitem {
                        padding: 12px 0;
                        &::before {
                            opacity: 0;
                            transition: padding 0.5s, opacity 0.3s ease-out;
                        }
                    }
                }
            }

            &__subItemsIcon {
                display: none;
            }

            &__subitem {
                transition: max-height 0.5s, padding 0.5s, opacity 0.3s,
                    padding-left 0.3s, color 0.3s ease-out;

                svg {
                    opacity: 1;
                    width: 24px;
                    transition: width 0.5s, opacity 0.5s;
                }
            }

            &__subitemLabel {
                opacity: 0;
                width: 0;
                transition: width 0.5s, opacity 0.5s;
                display: none;
            }

            &__subitems {
                // padding-left: 10px;
                padding-left: 25px;
            }
        }
    }

    &__scroll {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        padding-top: 20px;
        @include scrollbar;
    }

    &__item {
        position: relative;
        height: 50px;
        outline: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 12px 25px;
        color: $primary-color;
        width: 100%;
        transition: width 0.5s, background-color 0.5s, color 0.5s;
        background-color: $white;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 100%;
            width: 0;
            background-color: $primary-color;
            transition: width 0.5s;
        }

        &.-active {
            background-color: $primary-light-color;

            &::before {
                width: 4px;
            }

            .m-sidebar__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }

            svg {
                stroke: $primary-color;
            }
        }

        &:hover {
            background-color: $primary-color;
            color: $white;
            transition: background-color 0.5s, color 0.5s;

            .m-sidebar__itemLabel {
                color: $white;
            }

            .m-sidebar__subItemsIcon {
                &::after {
                    border-color: $white;
                }
            }

            svg {
                stroke: $white;
            }
        }

        svg {
            min-width: 24px;
            transition: stroke 0.3s;
        }
    }

    &__itemLabel {
        overflow: hidden;
        width: 100%;
        margin-left: 5px;
        padding-left: 10px;
        white-space: nowrap;
        color: $primary-color;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 24px;
        opacity: 1;
        transition: opacity 0.5s, padding-right 0.3s, width 0.5s, color 0.3s;
    }

    &__toggler {
        position: absolute;
        right: -17px;
        top: 30px;
        z-index: 2;
        display: block;
        height: 30px;
        width: 30px;
        border: 1px solid $gray-10;
        border-radius: 50%;
        background-color: $white;
        transition: 0.5s;
        cursor: pointer;

        @include media-down(s) {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 7px;
            height: 7px;
            border: 1.5px solid $primary-color;
            border-top: 0;
            border-right: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: transform 0.5s, left 0.5s, border-color 0.5s;
        }
    }

    &__subItemsIcon {
        position: absolute;
        right: 20px;
        transition: background-color 0.5s, border-color 0.5s;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 7px;
            height: 7px;
            border: 1.5px solid $primary-color;
            border-top: 0;
            border-right: 0;
            transition: transform 0.5s, left 0.5s, border-color 0.5s;
        }
    }

    &__itemContainer {
        display: flex;
        flex-direction: column;

        .m-sidebar__subitemLabel {
            pointer-events: none;
        }

        .m-sidebar__subitem {
            svg {
                pointer-events: none;
            }
        }

        &.-active {
            height: max-content;

            .m-sidebar__subitem {
                opacity: 1;
                max-height: 50px;
                padding: 12px 0;
                transition: max-height 0.5s, padding 0.5s, opacity 0.3s,
                    color 0.3s ease-in;

                &::before {
                    opacity: 1;
                    transition: opacity 0.5s ease-in;
                }
            }

            .m-sidebar__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }

            .m-sidebar__subitems {
                border-bottom: 1px solid $gray;
            }

            .m-sidebar__subitemLabel {
                pointer-events: all;
            }

            .m-sidebar__subitem {
                svg {
                    pointer-events: all;
                }
            }
        }
    }

    &__subitems {
        display: flex;
        flex-direction: column;
        padding-left: 70px;
        transition: padding-left 0.3s;
        background-color: $white;
    }

    &__subitem {
        color: $primary-color;
        display: flex;
        align-items: center;
        position: relative;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.5s, padding 0.5s, opacity 0.3s,
            padding-left 0.3s, color 0.3s ease-out;
        white-space: nowrap;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -16px;
            display: block;
            transform: translate(0, -50%) rotate(-135deg);
            width: 5px;
            height: 5px;
            border: 1.5px solid $primary-color;
            border-top: 0;
            border-right: 0;
            opacity: 0;
            transition: opacity 0.5s ease-out;
        }

        &.-active {
            color: $blue;
            svg {
                stroke: $blue;
            }

            &::before {
                border-color: $blue;
            }
        }

        &:hover {
            svg {
                stroke: $secondary-light-color;
                transition: stroke 0.5s;
            }

            &::before {
                border-color: $secondary-light-color;
                transition: border-color 0.5s;
            }
        }

        svg {
            opacity: 0;
            width: 0;
            stroke: $secondary-light-color;
            transition: width 0.5s, opacity 0.5s, stroke 0.5s;
        }
    }

    &__subitemLabel {
        display: block;
        width: auto;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 30px;
        opacity: 1;
        transition: color 0.5s, opacity 0.5s;


        svg {
            stroke: $primary-color;
            transition: stroke 0.5s;
        }

        &:hover {
            color: $secondary-light-color;
            transition: 0.3s;
        }
    }
}
