.-defaultSettings {
    .m-table {
        margin-bottom: 15px;
        h4 {
            margin: 25px 0;
            font-size: 16px;
        }

        &__results {
            min-height: 0;
        }

        &__header {
            margin-bottom: 0;
        }
    }
}