.m-map {
    &__container {
        max-width: 1300px;
        width: 100%;
        margin: 20px 1px 0;
        display: flex;
        flex-direction: column;

        .cesium-container {
            width: 100%;
            height: 600px;
            position: relative;

            .cesium-viewer-bottom {
                display: none;
            }
        }
    }

    &__infoWindow {
        position: absolute;
        top: 100px;
        right: 50px;
        margin-right: 20px;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

        @include media-down(m) {
            right: 10px;
        }

        .title {
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            background-color: rgba($primary-color, 0.6);
            padding: 10px;
            border-radius: 6px 6px 0 0;
            padding-right: 40px;
        }

        .description {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 300px;
            display: flex;
            padding: 10px;
            white-space: pre-wrap;
        }

        .actions {
            display: flex;
            align-items: center;
            padding: 10px;

            @include media-down(m) {
                flex-direction: column;
            }

            .a-btn {
                color: $white;
                background-color: rgba($primary-color, 1);
                margin-top: 0;
                &:not(:last-child) {
                    margin-right: 5px;
                }

                &:hover {
                    background-color: rgba($secondary-light-color, 1);
                }
            }
        }

        .m-modal__close {
            &::before,
            &::after {
                background-color: $white;
            }
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0 0;

        .selectedItem {
            background: $primary-light-color;
            border: 1px solid rgba($primary-color, 0.3);
            border-radius: 5px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: fit-content;
            font-size: 13px;
            margin: 5px;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);

            &.-draggable {
                cursor: pointer;
            }

            .label {
                font-weight: 600;
                margin-right: 10px;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                stroke: rgba($primary-color, 0.7);
                margin-left: 10px;
                cursor: pointer;
                transition: stroke 0.3s;

                &:hover {
                    stroke: $primary-color;
                }
            }
        }

        .m-inputGroup {
            width: auto;
            margin-bottom: 5px;
            margin-left: 5px;
        }
    }

    &__slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 20px;
        margin-top: 20px;
        font-size: 14px;

        .slider-input {
            text-align: center;
            padding: 2px;
        }

        .slider-component {
            margin: 15px 0 10px 0;
        }

        .rc-slider-track {
            background-color: $secondary-color;
        }

        .rc-slider-handle {
            border-color: $secondary-color;
            opacity: 1;
        }
    }
}
