.m-searchGroup {
    display: flex;
    align-items: flex-start;

    &__icon {
        background-color: $gray-2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        min-height: 44px;
        min-width: 44px;
        margin-top: 25px;
        margin-left: 5px;
        cursor: pointer;

        transition: background-color 0.2s ease-in-out;

        @include media-down(s) {
            min-width: auto;
        }

        &:hover {
            background-color: $gray-3;
        }

        &.-add {
            font-size: 14px;
            padding: 10px 20px;
            min-width: fit-content;
            color: $primary-color;

            span {
                line-height: 14px;
                text-transform: uppercase;
            }
        }
    }

    .m-modal__body {
        .a-btn {
            width: 100%;
        }
    }

    .m-inputGroup {
        width: 100%;

        label {
            white-space: nowrap;
        }
    }

    &.-disabled {
        .m-searchGroup__icon {
            opacity: 0.7;
            cursor: default;

            &:hover {
                background-color: $gray-2;
            }
        }
    }

    &.-disabled.-textarea {
        .m-searchGroup__icon {
            opacity: 0.7;
            cursor: default;

            &:hover {
                background-color: transparent;

                svg {
                    filter: none;
                }
            }
        }
    }

    &.-textarea {
        .m-textarea {
            width: 100%;
        }

        .m-searchGroup__icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }

        .m-searchGroup__icon {
            margin-top: 8px;
            background-color: transparent;

            svg {
                transition: filter 0.2s ease-in-out;
            }

            &:hover {
                svg {
                    filter: drop-shadow(0px 0px 3px rgba($primary-color, 1));
                }
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;

        .m-modal__body {
            min-width: 400px;
        }

        @include media-down(xs) {
            .m-searchGroup {
                flex-wrap: wrap;
            }

            .m-searchGroup__icon {
                min-width: 44px;
                margin-top: 0px;
            }
        }

        @include media-down(s) {
            .m-modal__body {
                min-width: auto;
            }
        }
    }

    &__loaderContainer {
        &.-singleMarkerButton {
            .m-map__infoWindow .a-btn {
                width: 100%;
            }
        }
    }
    .m-table {
        &__filters {
            .m-inputGroup {
                width: 150px;
            }
        }
    }
}
